const tabBtns = document.querySelectorAll('.tab-btn');
const tabContents = document.querySelectorAll('.tab-content');

tabBtns.forEach((btn, index) => {
  btn.addEventListener('click', () => {
    tabBtns.forEach((btn) => btn.classList.remove('bg-gray-100/50'));

    btn.classList.add('bg-gray-100/50');

    tabContents.forEach((content) => content.classList.add('sr-only'));

    tabContents[index].classList.remove('sr-only');
  });
});
